/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var $menu = $("#main-header"),
            $quickAccess = $("#quick-access");

        var showMenu = false,
            lastScrollTop = 0;
        /** show menu & quick access on scroll top */
        $(window).on('scroll', function(event){
          var st = $(this).scrollTop(),
              maxSt = $('body').height() - 150 - $(window).height();

          if (st > lastScrollTop){
            if (showMenu === true){
              showMenu = false;
              $menu.removeClass("active");
              $quickAccess.removeClass("active");
            }
          } else {
            // upscroll code
            if (showMenu === false && st > 100 && maxSt > st){
              showMenu = true;
              $menu.addClass("active");
              $quickAccess.addClass("active");
            }
            else if (showMenu === true && (st <= 100 || st >= maxSt )){
              showMenu = false;
              $menu.removeClass("active");
              $quickAccess.removeClass("active");
            }
          }
          lastScrollTop = st;
        });

        /** RESPONSIVE NAV TOGGLE */
        $('.nav-toggle').on('click', function(){
          $(this).parents('.nav-responsive').toggleClass('active');
          $(this).parents('.nav-responsive').find('ul').toggleClass('active');
          $(this).toggleClass('active');
          $('body').toggleClass('nav-is-active');
        });

        /** ISOTOPE */
        var $grid = $('.grid').isotope({
          // options
          itemSelector: '.grid-item',
          layoutMode: 'masonry'
        });
        $grid.imagesLoaded().progress( function() {
          $grid.isotope('layout');
        });

        /** SCROLL TOP LINK */
        $('.to-top-link').on('click', function(){
          event.preventDefault();
          $('htlm, body').animate({
            scrollTop: 0
          }, 300);
        });

        /** PAGE MODALE */
        $('.share-page-link').on('click', function(){
          event.preventDefault();
          $(".modal-share").addClass("active");
        });

        $('.contact-page-link').on('click', function(){
          event.preventDefault();
          $(".modal-contact").addClass("active");
          $(".contact-talent-text").removeClass("visible");
          if ($(this).data('contact')){
            var value = $(this).data('contact');
            $("input[id=contact-input]").val(value);
            $(".contact-talent-text").addClass("visible");
            $(".contact-talent-text .contact-talent-name").text(value);
            console.log(value, $(".contact-talent-name").text());
          }
          $('body').addClass('modal-is-active');
        });

        $('.btn-close').on('click', function(){
          event.preventDefault();
          var obj = $(this).data('close');
          $("."+obj).removeClass("active");
          $('body').removeClass('modal-is-active');
          $(".wpcf7").trigger('reset');
          $(".wpcf7").find('p').delay(200).show();
          $(".wpcf7-response-output, .wpcf7-not-valid-tip").hide();
        });


        /** CONTACT FORM 7 MADNESS */
        $(".wpcf7").on('wpcf7:invalid', function(event){
          console.log("INVALID");
        });
        $(".wpcf7").on('wpcf7:mailsent', function(event){
          console.log("SENT");
          $(".wpcf7").find('p').hide();
        });
        // $(".wpcf7").on('wpcf7:mailfailed', function(event){
        //   console.log("FAILED");
        // });
        // $(".wpcf7").on('wpcf7:submit', function(event){
        //   console.log("SUBMIT");
        // });
        // $(".wpcf7").on('wpcf7:spam', function(event){
        //   console.log("SPAM");
        // });

        /** TELENT GRID */
        $(".grid-item-small.cpt_talent").on({
          mouseenter: function () {
            $(this).find('.talent-infos, .logo-strech').addClass('active');
            },
            mouseleave: function () {
              $(this).find('.talent-infos, .logo-strech').removeClass('active');
            }
        });

        /** RESET FILTER (because YEAH) */
        $(".talent-filter-reset").on('click', function(){
          $form = $(this).parents('form');
          $form.find("select option").filter(':nth-child(1)').prop('selected', true);
          $form.find("input.talent-search").val('');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        /** braking news */
        if ($("#breaking-news").length > 0) {
          //init
          $("#breaking-news article:first-child, #breaking-news li:first-child").addClass("current");

          var $items = $('#breaking-news article'),
              $pagination = $('#breaking-news .breaking-news-pagination li');

          if ($items.filter('.current').hasClass("bg-dark")){
            $("#breaking-news").addClass("current-bg-dark");
          }
          else if ($items.filter('.current').hasClass("bg-purple")){
            $("#breaking-news").addClass("current-bg-purple");
          }
          else {
            $("#breaking-news").removeClass("current-bg-purple");
            $("#breaking-news").removeClass("current-bg-dark");
          }

          $pagination.on('click', function(){
            var index = $(this).data('page');
            switch (index) {
              case 1:
                // if index = 1, then other = .right,
                $items.filter('.current').removeClass('current');
                $pagination.filter('.current').removeClass('current');

                $items.filter(':nth-child(3)').removeClass('left').addClass('right');
                $items.filter(':nth-child(2)').removeClass('left').addClass('right');
                $items.filter(':nth-child(1)').removeClass('left right').addClass('current');
                $(this).addClass('current');
                break;
              case 2:
                // if i = 2, 1 = .left and 3 = right
                $items.filter('.current').removeClass('current');
                $pagination.filter('.current').removeClass('current');

                $items.filter(':nth-child(1)').removeClass('right').addClass('left');
                $items.filter(':nth-child(3)').removeClass('left').addClass('right');
                $items.filter(':nth-child(2)').removeClass('left right').addClass('current');
                $(this).addClass('current');
                break;
              case 3:
                // if 3, others = .left
                $items.filter('.current').removeClass('current');
                $pagination.filter('.current').removeClass('current');

                $items.filter(':nth-child(1)').removeClass('right').addClass('left');
                $items.filter(':nth-child(2)').removeClass('right').addClass('left');
                $items.filter(':nth-child(3)').removeClass('left right').addClass('current');
                $(this).addClass('current');
                break;
              default:
                console.log("default");
            }
            if ($items.filter('.current').hasClass("bg-dark")){
              $("#breaking-news").addClass("current-bg-dark");
            }
            else if ($items.filter('.current').hasClass("bg-purple")){
              $("#breaking-news").addClass("current-bg-purple");
            }
            else {
              $("#breaking-news").removeClass("current-bg-purple");
              $("#breaking-news").removeClass("current-bg-dark");
            }
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        /** LAUNDCH CANVAS GAME HERE */
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
